<template>
    <apexchart type="pie"
               width="600"
               class="justify-center d-flex"
               :key="processo.oid + id"
               :options="pieOptions"
               :series="pieSeries"></apexchart>
</template>

<script>
export default {
  name: "SituacaoCandidatos",
  props: {
    processoEstatistica: {type: Object, required: true},
    processo: {type: Object, required: true}
  },
  data(){
    return {
      id:"0",
      pieSeries: [],
      pieOptions: {
        chart: {
          animations: {
            enabled: false
          },
          width: 380,
          type: 'pie',
          toolbar: {
            show: true
          },
        },
        title: {
          text: '',
          floating: false,
          offsetY: 0,
          offsetX: 0,
          align: 'left',
          style: {
            color: '#444'
          }
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },

            legend: {
              position: 'bottom'
            }
          },

        }],
        dataLabels: {
          enabled: true,
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName.substring(0, seriesName.indexOf(':') + 1),
            },
          },
        }
      },
    }
  },
  watch: {
    processoEstatistica: {
      handler(newVal){
        this.criaGraficoEstatisticaPorSituacao(newVal);
        this.id += 1;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    criaGraficoEstatisticaPorSituacao(processoEstatistica){
      let mapaSituacoes = processoEstatistica.mapaTotalInscricoes;
      let listaSituacoes = Object.entries(mapaSituacoes);
      listaSituacoes.forEach(s => s[0] = (s[0] + ": " + s[1]));
      mapaSituacoes = Object.fromEntries(listaSituacoes);
      this.pieSeries = Object.values(mapaSituacoes);
      this.pieOptions.labels = Object.keys(mapaSituacoes);
      this.pieOptions.title.text = 'Estatísticas por Situação - ' + this.processo.nome;
    }
  }
}
</script>

<style scoped>

</style>