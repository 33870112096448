<template>
  <apexchart type="bar"
             width="100%"
             height="600"
             class="justify-center"
             :key="processo.oid+id"
             :options="barOptions"
             :series="barSeries"
             ></apexchart>
</template>

<script>
export default {
  name: "CandidatosOpcao",
  props:{
    processo: {type: Object, required: true},
    processoEstatistica: {type: Object, required: true}
  },
  data(){
    return {
      id: '0',
      barSeries: [],
      barOptions: {
        title: {
          text: 'Estatísticas por Curso Opção',
          floating: false,
          align: 'center'
        },
        chart: {
          animations: {
            enabled: false
          },
          type: 'bar',
          stacked: true,
          toolbar: {
            show: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: 'string',
          categories: [],
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '10pt'
            }
          }
        },
        fill: {
          opacity: 1
        }
      },
    }
  },
  watch:{
    processoEstatistica:{
      handler(newVal){
        this.criaGraficoEstatisticaPorOpcao(newVal);
        this.id += 1;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    criaGraficoEstatisticaPorOpcao(processoEstatistica) {
      this.barOptions.title.text = this.processo.nome + ' - (10 opções com mais inscrições)'
      let opcoesMapaInscricaoSituacao = processoEstatistica.opcoesMapaInscricaoSituacao;
      let mapaTotalInscricoes = processoEstatistica.mapaTotalInscricoes;
      let series = [];
      let categorias = new Set();
      let chaves = Object.keys(mapaTotalInscricoes);
      chaves.forEach(chave => {
        let serie = {name: chave, data: []}
        opcoesMapaInscricaoSituacao.forEach(opcao => {
          if (Object.prototype.hasOwnProperty.call(opcao.mapaInscricaoSituacao, chave)) {
            serie.data.push(opcao.mapaInscricaoSituacao[chave]);
          } else {
            serie.data.push(0);
          }
          categorias.add(opcao.nomeOpcao);
        })

        series.push(serie);
      })
      this.barSeries = series;
      this.barOptions.xaxis.categories = Array.from(categorias);
    },
  }
}
</script>

<style scoped>

</style>