<template>
  <div>
    <v-row justify="center">
      <v-col cols="6">
        <v-autocomplete
            v-model="opcaoSelecionada"
            :items="opcoes"
            outlined
            label="Selecione uma opção"
            item-text="nome"
            item-value="oid"
            return-object
            class="mt-2"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-4 mt-n2">
      <v-tooltip right>
        <template v-slot:activator="{on,attrs}">
          <v-icon v-on="on" v-bind="attrs" color="info">mdi-information-outline</v-icon>
        </template>
        <span>O gráfico abaixo mostra os diferentes cursos origem pertencentes aos candidatos inscritos na opção selecionada. </span>
      </v-tooltip>
    </v-row>
    <div class="d-flex justify-center align-center mt-16">
      <v-progress-circular
          v-if="loadingEstatisticas"
          :size="64"
          :width="6"
          color="primary"
          indeterminate/>
    </div>
    <apexchart v-if="treeSeries.length && treeSeries[0].data.length && !loadingEstatisticas" :key="processo.oid + id" type="treemap"
               height="600" :options="treeOptions" :series="treeSeries"
               class="justify-center d-flex"></apexchart>
    <v-row v-if="treeSeries.length && !treeSeries[0].data.length && !loadingEstatisticas" align="center" justify="center">
      <v-alert type="info" outlined border="left">
        Não existem inscrições para essa opção.
      </v-alert>
    </v-row>
  </div>
</template>

<script>
import estatisticaService from "@/services/estatistica.service";

export default {
  name: "CursoOrigemOpcao",
  props: {
    processoEstatistica: {type: Object, required: true},
    processo: {type: Object, required: true}
  },
  data(){
    return {
      id: 0,
      opcoes: [],
      opcaoSelecionada: null,
      treeSeries: [],
      treeOptions: {
        colors: [
          '#3B93A5',
          '#F7B844',
          '#ADD8C7',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB'
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        },
        legend: {
          show: false
        },
        chart: {
          animations: {
            enabled: false
          },
          height: 350,
          type: 'treemap'
        },
        title: {
          text: 'Cursos Origem por Opção ',
          align: 'center'
        }
      },
      loadingEstatisticas: false,
    }
  },
  watch: {
    processoEstatistica: {
      handler(){
        this.opcaoSelecionada = null;
        this.opcoes = this.processoEstatistica.opcoes;
        this.treeSeries = [];
        this.id += 1;
      },
      deep: true,
      immediate: true
    },
    opcaoSelecionada: {
      async handler() {
        if (this.opcaoSelecionada) {
          this.loadingEstatisticas = true;
          await estatisticaService.recuperarEstatisticaPorOpcao(this.opcaoSelecionada.oid).then(response => {
            this.criaGraficoCursoOrigemPorOpcao(response.data);
          });
        }
      },
      deep: true
    }
  },
  methods: {
    criaGraficoCursoOrigemPorOpcao(opcaoEstatistica) {
      let cursosOrigem = {data: []};
      if (opcaoEstatistica.qtdCursoOrigemInscricoes) {
        for (const cursoOrigem of Object.keys(opcaoEstatistica.qtdCursoOrigemInscricoes)) {
          let cursoQtdInscricoes = {
            x: cursoOrigem.split('-'),
            y: opcaoEstatistica.qtdCursoOrigemInscricoes[cursoOrigem]
          };
          cursosOrigem.data.push(cursoQtdInscricoes);
        }
      }
      this.treeSeries = [];
      this.treeSeries.push(cursosOrigem);
      this.treeOptions.title.text = 'Cursos Origem por Opção - (' + this.opcaoSelecionada.nome + ')';
      this.loadingEstatisticas = false;
    }
  }
}

</script>

<style scoped>

</style>