<template>
  <v-card>
    <v-card-title class="justify-center mb-3 titulo">
      <h2>
        Estatísticas de Processos
      </h2>
    </v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="6">
          <v-autocomplete
              class="ingiravel"
              v-model="processoSelecionado"
              :items="processos"
              :loading="loading"
              :search-input.sync="busca"
              color="primary"
              hide-no-data
              hide-selected
              item-text="nome"
              item-value="oid"
              label="Processo"
              placeholder="Digite o nome do processo desejado"
              append-icon="mdi-magnify"
              return-object
              outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <div v-if="processoSelecionado">
            <v-divider/>
            <v-tabs v-model="tab" v-if="processoEstatistica && !loadingEstatisticas" class="d-flex justify-center mb-3">
              <v-tab v-for="(tipoEstatistica, index) in filteredTiposEstatisticas" :key="index">
                {{ tipoEstatistica.titulo }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" v-if="processoEstatistica && !loadingEstatisticas">
              <v-tab-item v-for="(tipoEstatistica, index) in filteredTiposEstatisticas" :key="index">
                <component :is="tipoEstatistica.component" :processo="processoSelecionado" :processoEstatistica="processoEstatistica"/>
              </v-tab-item>
            </v-tabs-items>
            <div class="d-flex justify-center align-center mt-16">
              <v-progress-circular
                  v-if="loadingEstatisticas"
                  :size="64"
                  :width="6"
                  color="primary"
                  indeterminate/>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import processoService from "@/services/processo.service";
import estatisticaService from "@/services/estatistica.service";
import SituacaoCandidatos from "@/components/pages/admin/processos/tiposEstatisticas/SituacaoCandidatos";
import CandidatosOpcao from "@/components/pages/admin/processos/tiposEstatisticas/CandidatosOpcao";
import CursoOrigemOpcao from "@/components/pages/admin/processos/tiposEstatisticas/CursoOrigemOpcao";
import InstituicoesOrigemOpcao from "@/components/pages/admin/processos/tiposEstatisticas/InstituicoesOrigemOpcao";

export default {
  name: "Estatísticas",
  components: {CursoOrigemOpcao, CandidatosOpcao, SituacaoCandidatos, InstituicoesOrigemOpcao},
  data() {
    return {
      tab: 0,
      busca: null,
      processos: [],
      processoSelecionado: null,
      processoEstatistica: null,
      tiposEstatisticas: [
        {titulo: 'Estatísticas por Situação', component: SituacaoCandidatos},
        {titulo: 'Estatísticas por Opção', component: CandidatosOpcao},
        {titulo: 'Cursos Origem por Opção', component: CursoOrigemOpcao, restrictedTo: ['MUD', 'MUF']},
        {titulo: 'Candidatos por Instituição de Origem', component: InstituicoesOrigemOpcao, restrictedTo: ['TEE']}
      ],
      loading: false,
      loadingEstatisticas: false,
    }
  },

  updated() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    })
  },

  watch: {
    busca(val) {
      if (val && val.length >= 3) {
        this.loading = true;
        processoService.recuperarProcessoConsultaPorNome(val).then(response => {
          this.processos = response.data;
        })
        this.loading = false;
      } else {
        this.processos = [];
      }
    },
    processoSelecionado: {
      handler() {
        if (this.processoSelecionado) {
            this.loadingEstatisticas = true;
            estatisticaService.recuperarEstatisticasPorProcesso(this.processoSelecionado.oid).then(response => {
            this.processoEstatistica = response.data;
            this.loadingEstatisticas = false;
          });
        }
      },
      deep: true
    },
  },
  computed:{
    filteredTiposEstatisticas() {
      return this.tiposEstatisticas.filter(tipoEstatistica => this.tipoEstatisticaDisponivel(tipoEstatistica));
    }
  },
  methods: {
    tipoEstatisticaDisponivel(tipoEstatistica){
      if(!tipoEstatistica.restrictedTo) return true;
      return tipoEstatistica.restrictedTo.includes(this.processoSelecionado.tipoProcessoCodigo);
    }
  }
}
</script>

<style scoped>
</style>