<template>
  <div>
    <v-row justify="center">
      <v-col cols="6">
        <v-autocomplete
            v-model="opcaoSelecionada"
            :items="opcoes"
            outlined
            label="Selecione uma opção"
            item-text="nome"
            item-value="oid"
            return-object
            class="mt-2"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-4 mt-n2">
      <v-tooltip right>
        <template v-slot:activator="{on,attrs}">
          <v-icon v-on="on" v-bind="attrs" color="info">mdi-information-outline</v-icon>
        </template>
        <span>O gráfico abaixo mostra a quantidade de candidatos inscritos por instituição de ensino de origem. </span>
      </v-tooltip>
    </v-row>
    <div class="d-flex justify-center align-center mt-16">
      <v-progress-circular
          v-if="loadingEstatisticas"
          :size="64"
          :width="6"
          color="primary"
          indeterminate/>
    </div>
    <apexchart v-if="series.length && series[0].data.length && !loadingEstatisticas"
               type="bar"
               width="100%"
               height="600"
               class="justify-center"
               :key="processo.oid+id"
               :options="options"
               :series="series"
    ></apexchart>
    <v-row v-if="series.length && !series[0].data.length && !loadingEstatisticas" align="center" justify="center">
      <v-alert type="info" outlined border="left">
        Não existem inscrições para essa opção.
      </v-alert>
    </v-row>
  </div>
</template>

<script>
import estatisticaService from "@/services/estatistica.service";

export default {
  name: "InstituicoesOrigemOpcao",
  props: {
    processoEstatistica: {type: Object, required: true},
    processo: {type: Object, required: true}
  },
  data(){
    return {
      id: 0,
      opcoes: [],
      opcaoSelecionada: null,
      series: [],
      options: {
        title: {
          text: 'Estatísticas por Curso Opção',
          floating: false,
          align: 'center'
        },
        chart: {
          animations: {
            enabled: false
          },
          type: 'bar',
          stacked: true,
          toolbar: {
            show: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: 'string',
          categories: [],
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '10pt'
            }
          }
        },
        fill: {
          opacity: 1
        }
      },
      loadingEstatisticas: false,
    }
  },
  watch: {
    processoEstatistica: {
      handler(){
        this.opcaoSelecionada = null;
        this.opcoes = this.processoEstatistica.opcoes;
        this.options.title.text = ''
        this.id += 1;
      },
      deep: true,
      immediate: true
    },
    opcaoSelecionada: {
      async handler() {
        if (this.opcaoSelecionada) {
          this.options.title.text = ''
          this.series = [];
          this.loadingEstatisticas = true;
          await estatisticaService.recuperarInscricoesTEEInstituicaoOrigemPorOpcao(this.opcaoSelecionada.oid).then(response => {
            this.popularGraficoInstituicoesOrigem(response.data);
          });
        }
      },
      deep: true
    }
  },
  methods: {
    popularGraficoInstituicoesOrigem(inscricoesTEE) {

      var situacoes = new Set();
      var mapaCodigoIes = new Map();
      inscricoesTEE.forEach(inscricaoTEE =>{
        let nomeSituacao = inscricaoTEE.inscricao.situacao.nome;
        situacoes.add(nomeSituacao);

        let ies = inscricaoTEE.instituicaoEnsino;
        let iesObject =  mapaCodigoIes.get(ies.codigo) || {nome: ies.nome, mapaSituacoes: new Map()}
        let mapaSituacoes = iesObject.mapaSituacoes;
        mapaSituacoes.set(nomeSituacao, (mapaSituacoes.get(nomeSituacao) || 0) + 1);
        mapaCodigoIes.set(ies.codigo, iesObject)
      });

      var categories = [];
      var mapaSituacaoQtdIess = new Map();
      situacoes.forEach(situacao => mapaSituacaoQtdIess.set(situacao, []));
      mapaCodigoIes.forEach(ies => {
        categories.push(ies.nome);

        situacoes.forEach(situacao => {
          let qtdIess = (ies.mapaSituacoes.get(situacao) || 0);
          mapaSituacaoQtdIess.get(situacao).push(qtdIess);
        })
      });

      var series = [];
      var situacoesSorted = Array.from(situacoes).sort();
      situacoesSorted.forEach(situacao => {
        series.push({name: situacao, data: mapaSituacaoQtdIess.get(situacao)})
      });

      this.options.title.text = 'Candidatos por Instituição de Ensino de Origem - Opção ('+ this.opcaoSelecionada.nome + ')';
      this.options.xaxis.categories = categories;
      this.series = series;
      this.loadingEstatisticas = false;
    }
  }
}
</script>

<style scoped>

</style>