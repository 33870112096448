import Vue from "vue";
export default {
    recuperarEstatisticaPorOpcao(opcaoOid) {
        return Vue.axios.get("estatistica/opcao/" + opcaoOid);
    },

    recuperarEstatisticasPorProcesso(processoOid) {
        return Vue.axios.get("estatistica/processo/" + processoOid);
    },
    recuperarInscricoesTEEInstituicaoOrigemPorOpcao(opcaoOid){
        return Vue.axios.get("estatistica/opcao/"+opcaoOid+"/tee/inscricoesTEE/instituicoesOrigem")
    }
}